import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../commonComponents/Input/Input";
import { ProgressBar } from "react-bootstrap";
import { editorConfigAr, editorConfigEn } from "../../utils/editorConfig";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import {
  addEvents,
  getEventDetails,
  updateEvents,
  uploadFile,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import SortableList from "../sortFiles/SortableList";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  detail_event_loading,
  event_details,
  fileUploadLoader,
  update_events_loading,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { valueSplitFunction } from "../../utils/helpers";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import TextArea from "../../commonComponents/TextArea/TextArea";
import DatePick from "../../commonComponents/DatePick/DatePick";
import moment from "moment";
import useTranslate from "../../utils/Hooks/useTranslate";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";

export default function EventsAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const loading = useSelector(detail_event_loading);
  const details = useSelector(event_details);
  const updateLoading = useSelector(update_events_loading);
  const uploadLoading = useSelector(fileUploadLoader);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [addAttachmentsLoader, setAddAttachmentsLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [addAttachmentProgress, setAddAttachmentProgress] = useState(0);
  const [trackId, setTrackId] = useState("");
  const [addThumbLoader, setAddThumbLoader] = useState(false);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({ events_gallery: [] });

  useEffect(() => {
    if (id) {
      dispatch(getEventDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        title_ar: details?.title_ar,
        description: details?.description,
        description_ar: details?.description_ar,
        publish: details?.publish?.toString(),
        location: details?.location ?? "",
        event_date: details?.event_date
          ? new Date(moment.utc(details?.event_date).format("YYYY-MM-DD"))
          : "",
        event_start_time: details?.event_start_time
          ? timeToDateConverter(details?.event_start_time)
          : "",
        event_end_time: details?.event_end_time
          ? timeToDateConverter(details?.event_end_time)
          : "",
      };
      if (details?.events_gallery?.length) {
        let arr = details?.events_gallery?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            url: `${IMAGE_BASE_URL}events/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
            id: ele + i + 1,
          };
          return obj;
        });
        result = {
          ...result,
          events_gallery: [...arr],
        };
      }
      if (details?.featured_image?.length) {
        result = {
          ...result,
          featured_image: [
            {
              type: details?.featured_image.split(".").pop(),
              url: `${IMAGE_BASE_URL}events/${details?.featured_image}`,
              name: valueSplitFunction(details?.featured_image),
              response: `${details?.featured_image}`,
            },
          ],
        };
      }
      // if (details?.thumnail?.length) {
      //   result = {
      //     ...result,
      //     thumnail: [
      //       {
      //         type: details?.thumnail.split(".").pop(),
      //         url: `${IMAGE_BASE_URL}events/${details?.thumnail}`,
      //         name: valueSplitFunction(details?.thumnail),
      //         response: `${details?.thumnail}`,
      //       },
      //     ],
      //   };
      // }
      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Events") : t("Create Events"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        dispatch(
          uploadFile(
            formData,
            (res) => {
              let oldFiles = e?.filter((el) => el?.metaFile == undefined);
              let arr = e
                ?.filter((el) => el?.metaFile != undefined)
                ?.map((ele, i) => {
                  return {
                    ...ele,
                    response: res?.data[i],
                    id: res?.data[i] + i + 1,
                  };
                });
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: [...oldFiles, ...arr],
              }));
              setImageLoader(false);
            },
            (progress) => {
              setAddAttachmentProgress(progress);
            }
          )
        );
      }
    }
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        if (field == "featured_image") {
          setAddAttachmentsLoader(true);
        } else {
          setAddThumbLoader(true);
        }

        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        dispatch(
          uploadFile(
            formData,
            (res) => {
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: [
                  {
                    type: e[0].type,
                    url: e[0].url,
                    name: e[0]?.name,
                    metaFile: e,
                    response: res?.data[0],
                  },
                ],
              }));
              if (field == "featured_image") {
                setAddAttachmentsLoader(false);
              } else {
                setAddThumbLoader(false);
              }
            },
            (progress) => {
              setAddAttachmentProgress(progress);
            }
          )
        );
      }
    }
  };

  const handleDelete = (file, field) => {
    let temp = [...inputs?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?.id !== file?.id);
      setInputs({
        ...inputs,
        [field]: temp,
      });
    }
  };

  const onDetailsEditorChange = (content, field) => {
    setInputs({
      ...inputs,
      [field]: content,
    });
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    // navigate(-1);
  };

  const getMinTime = (minFullTime) => {
    if (
      moment(minFullTime, "h:mm A").format("HH:mm") <
      moment("07:00 AM", "h:mm A").format("HH:mm")
    ) {
      return new Date(new Date(minFullTime).setHours(7, 0, 0));
    } else {
      return minFullTime;
    }
  };

  const timeToDateConverter = (timeString) => {
    const [time, period] = timeString.split(" ");
    const [hours, minutes] = time.split(":");
    const isPM = period.toLowerCase() === "pm";
    let dateWithTime = new Date();
    dateWithTime.setHours(
      (parseInt(hours) % 12) + (isPM ? 12 : 0),
      parseInt(minutes),
      0,
      0
    );
    return dateWithTime;
  };

  const submitHandler = async () => {
    let isValidated = await validate();
    if (isValidated && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("title", inputs?.title ?? "");
    formData.append("title_ar", inputs?.title_ar ?? "");
    formData.append("description", inputs?.description ?? "");
    formData.append("description_ar", inputs?.description_ar ?? "");
    formData.append("location", inputs?.location ?? "");
    formData.append("publish", inputs?.publish ?? "");
    formData.append(
      "event_date",
      inputs?.event_date
        ? moment(inputs?.event_date)
          .format("YYYY-MM-DD hh:mm:ss")
          ?.split(" ")[0] + " 00:00:00"
        : ""
    );
    formData.append(
      "event_start_time",
      inputs?.event_start_time
        ? moment(inputs?.event_start_time).format("LT")
        : ""
    );
    formData.append(
      "event_end_time",
      inputs?.event_end_time ? moment(inputs?.event_end_time).format("LT") : ""
    );
    inputs?.events_gallery?.length &&
      inputs?.events_gallery?.forEach((item, i) =>
        formData.append("events_gallery[]", item?.response)
      );
    formData.append(
      "featured_image",
      inputs?.featured_image?.[0]?.response ?? ""
    );
    // formData.append("thumnail", inputs?.thumnail?.[0]?.response ?? "");
    formData.append("track_id", trackId);
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updateEvents(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addEvents(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  return (
    <div id="events_addRequest">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit Events" : "Add Events"} />
          <BreadCrump
            content={[
              {
                name: id ? "Edit Events" : "Add Events",
              },
              {
                name: "Events",
                path: "/events/events-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={id ? "Edit Events" : "Add Events"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <Input
              className="css-inp-esc1"
              label="Title in English"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              field={"title"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <Input
              className="css-inp-esc1"
              label="Title in Arabic"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              // validation={{
              //   alphabetsOnly: { lang: "ar", more: '!@#$%^&*()_+-=><.,:;"' },
              // }}
              field={"title_ar"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <TextEditor
              lang='en'
              config={editorConfigEn}
              label={"Description in English"}
              className={"editor-ltr mb-3"}
              field={"description"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "description")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter description in english") }}
              placeholder="Type Here"
            />
            <TextEditor
              lang='ar'
              config={editorConfigAr}
              label={"Description in Arabic"}
              style={{ direction: "rtl" }}
              field={"description_ar"}
              className={"mb-3"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "description_ar")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter description in arabic") }}
              placeholder="Type Here"
            />
            <div className="row">
              <div className="col-md-3">
                <DatePick
                  className="css-dtp-esc2"
                  title={"Event Date"}
                  placeholder="DD/MM/YYYY"
                  selected={inputs?.event_date}
                  onChange={(e, key) => {
                    setInputs((s) => ({
                      ...s,
                      event_start_time: "",
                      event_end_time: "",
                      duration: "",
                    }));
                    inputHandler(e, key);
                  }}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  error={errors?.event_date}
                  inputSocket={formPlug}
                  required={{ message: t("Please select event date") }}
                  field={"event_date"}
                  showMonthDropdown
                  showYearDropdown
                />
              </div>
              <div className="col-md-2">
                <DatePick
                  selected={inputs?.event_start_time}
                  onChange={(e, key) => {
                    setInputs((s) => ({
                      ...s,
                      event_end_time: "",
                      duration: "",
                    }));
                    inputHandler(e, key);
                  }}
                  className="css-dtp-esc2 w-100"
                  dateFormat="h:mm aa"
                  minTime={getMinTime(
                    inputs.event_date > new Date()
                      ? new Date(new Date(inputs.event_date).setHours(0, 0, 0))
                      : new Date()
                  )}
                  maxTime={
                    inputs?.event_end_time
                      ? new Date(inputs?.event_end_time)
                      : new Date().setHours(23, 59)
                  }
                  placeholder="-- : --"
                  field={"event_start_time"}
                  title={"Start Time"}
                  inputSocket={formPlug}
                  required={{ message: t("Please select start time") }}
                  error={errors?.event_start_time}
                  timeSelectOnly
                />
              </div>
              <div className="col-md-2">
                <DatePick
                  timeSelectOnly
                  selected={inputs?.event_end_time}
                  onChange={inputHandler}
                  className="css-dtp-esc2 w-100"
                  dateFormat="h:mm aa"
                  // minTime={getMinTime(
                  //   inputs.event_date > new Date()
                  //     ? inputs.event_start_time
                  //       ? new Date(inputs.event_start_time)
                  //       : new Date(new Date(inputs.event_date).setHours(0, 0, 0))
                  //     : inputs.event_start_time
                  //     ? new Date(inputs.event_start_time)
                  //     : new Date()
                  // )}
                  minTime={getMinTime(
                    inputs.event_date > new Date()
                      ? inputs.event_start_time
                        ? new Date(
                          new Date(inputs.event_start_time).setMinutes(
                            new Date(inputs.event_start_time).getMinutes() + 15
                          )
                        )
                        : new Date(new Date(inputs.event_date).setHours(0, 0, 0))
                      : inputs.event_start_time
                        ? new Date(
                          new Date(inputs.event_start_time).setMinutes(
                            new Date(inputs.event_start_time).getMinutes() + 15
                          )
                        )
                        : new Date()
                  )}
                  maxTime={new Date().setHours(23, 59)}
                  // minTime={getMinTime(
                  //   inputs.event_date > new Date()
                  //     ? inputs.event_start_time
                  //       ? new Date(
                  //           new Date(inputs.event_start_time).setMinutes(
                  //             new Date(inputs.event_start_time).getMinutes() +
                  //               15
                  //           )
                  //         )
                  //       : new Date(new Date(inputs.event_date).setHours(0, 0, 0))
                  //     : inputs.start_time
                  //     ? new Date(
                  //         new Date(inputs.event_start_time).setMinutes(
                  //           new Date(inputs.event_start_time).getMinutes() + 15
                  //         )
                  //       )
                  //     : new Date()
                  // )}
                  // maxTime={new Date().setHours(23, 59)}
                  placeholder="-- : --"
                  field={"event_end_time"}
                  title={"End Time"}
                  inputSocket={formPlug}
                  required={{ message: t("Please select end time") }}
                  error={errors?.event_end_time}
                  disabled={inputs?.event_start_time?.length === 0}
                />
              </div>
            </div>
            <Input
              className="css-inp-esc1"
              label={"Location"}
              placeholder={"Enter Address"}
              required={{ message: t("Please enter location details") }}
              field={"location"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              span="start fill"
            />
            {/* <TextArea
              className={"css-txt-esc1 mt-3"}
              label={"Location"}
              placeholder={"Enter Address"}
              field={"location"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              span="start fill"
              required={{ message: t("Please enter location details") }}
            /> */}
            <div>
              <div className="d-flex gap-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc2  mt-3"
                  label="Upload Feature Image"
                  buttonLabel="Choose File"
                  accept={["jpg", "jpeg", "png"]}
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      feature_image: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "featured_image"
                    );
                  }}
                  loader={addAttachmentsLoader}
                  error={errors.featured_image}
                  field={"featured_image"}
                  id="featured_image"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.featured_image}
                  required={{ message: t("Please upload featured image") }}
                  inputSocket={formPlug}
                />
              </div>
              <SupportedFormats formats={["jpg", "jpeg", "png"]} maxSize={50} />
            </div>
            {/* <div>
              <div className="d-flex gap-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc2  mt-3"
                  label="Upload Thumbnail"
                  buttonLabel="Choose File"
                  accept={["jpg", "jpeg", "png"]}
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      feature_image: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "thumnail"
                    );
                  }}
                  loader={addThumbLoader}
                  error={errors.thumnail}
                  field={"thumnail"}
                  id="thumnail"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.thumnail}
                  required={{ message: t("Please upload thumbnail image") }}
                  inputSocket={formPlug}
                />
              </div>
              <SupportedFormats formats={["jpg", "jpeg", "png"]} maxSize={50} />
            </div> */}
            <div>
              <div className="d-flex gap-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc2  mt-3"
                  label="Upload Gallery Files"
                  noLabel
                  buttonLabel="Choose Files"
                  accept={["jpg", "jpeg", "png", "mp4", "mov", "avi"]}
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      events_gallery: "",
                    }));
                    fileSelectedHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "events_gallery"
                    );
                  }}
                  field={"events_gallery"}
                  loader={imageLoader}
                  error={errors.events_gallery}
                  id="events_gallery"
                  maxFileSizeMB={100}
                  multiple={true}
                  files={inputs?.events_gallery}
                  // required={{
                  //   message: "Please upload events galley files",
                  // }}
                  inputSocket={formPlug}
                />
                <ProgressBar progress={addAttachmentProgress} width={350} />
              </div>
              <SupportedFormats
                formats={["jpg", "jpeg", "png", "mp4", "mov", "avi"]}
                maxSize={100}
              />
            </div>
            {inputs?.events_gallery?.length ? (
              <SortableList
                setInputs={setInputs}
                inputs={inputs}
                field="events_gallery"
                handleDelete={handleDelete}
                randomTrackID={trackId ?? ""}
              />
            ) : (
              <></>
            )}
            <div>
              <RadioButtons
                label={"Publish"}
                buttons={[
                  {
                    label: "Yes",
                    value: "1",
                  },
                  {
                    label: "No",
                    value: "0",
                  },
                ]}
                name="publish"
                placeholder={"Select any option"}
                required={{ message: t("Please select publish status") }}
                field={"publish"}
                error={errors.publish}
                value={{
                  publish: inputs?.publish,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Submit"
              onClick={() => submitHandler()}
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Cancel"
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
              onClick={() => onCancelHandler()}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
