import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Login from "../pages/general/Login";
import PageNotFound from "../pages/general/PageNotFound";
import PrivateRoutes from "../utils/PrivateRoutes";
import NewsList from "../pages/news/news_list";
import NewsAdd from "../pages/news/news_add";
import ManageUsers from "../pages/manageUsers/userList";
import ManageRoles from "../pages/mangeRoles/roleList";
import AddRole from "../pages/mangeRoles/addRole";
import usePrevRouteCatcher from "../utils/Hooks/usePrevRouteCatcher";
import EditRole from "../pages/mangeRoles/editRole";
import NewsComments from "../pages/news/news_comments";
import AnnouncementList from "../pages/announcements/announcement_list";
import AnnouncementAdd from "../pages/announcements/announcement_add";
import TrainingList from "../pages/training/training_list";
import TrainingAdd from "../pages/training/training_add";
import EventsList from "../pages/events/events_list";
import EventsAdd from "../pages/events/events_add";
import TrainingApplicants from "../pages/training/training_applicants";
import EventsApplicants from "../pages/events/events_applicants";
import GalleryAdd from "../pages/gallery/gallery_add";
import GalleryLists from "../pages/gallery/gallery_lists";
import BenefitsList from "../pages/benefits/benefits_list";
import BenefitsAdd from "../pages/benefits/benefits_add";
import QuickLinksList from "../pages/quickLinks/quick_links_list";
import QuickLinksAdd from "../pages/quickLinks/quick_links_add";
import FAQAdd from "../pages/faq/faq_add";
import FAQList from "../pages/faq/faq_list";
import PolicyAdd from "../pages/policy/policy_add";
import PolicyList from "../pages/policy/policy_list";
import CalenderList from "../pages/calender/calender_list";
import CalenderAdd from "../pages/calender/calender_add";
import SurveyAdd from "../pages/survey/survey_add";
import SurveyList from "../pages/survey/survey_list";
import SurveyAnswerDetails from "../pages/survey/survey_answerDetails";
import SurveyViewQuestions from "../pages/survey/survey_viewQuestions";
import TranslationAdmin from "../pages/translation/translation_admin";
import TranslationWeb from "../pages/translation/translation_web";
import { modulePermissionKeys, userType } from "../utils/helpers";
import LoadingSpinner from "../components/LoadingSpinner/LoadingSpinner";
import Dashboard from "../pages/general/Dashboard";
import SurveyView from "../pages/survey/survey_view";

function RouteHandler() {
  usePrevRouteCatcher();

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Dashboard />} />
            {modulePermissionKeys()?.includes("news") ||
            userType() == "super_admin" ? (
              <>
                <Route path="news/news-list" element={<NewsList />} />
                <Route path="news/add" element={<NewsAdd />} />
                <Route path="news/update/:id" element={<NewsAdd />} />
                <Route path="news/comments/:id" element={<NewsComments />} />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("announcements") ||
            userType() == "super_admin" ? (
              <>
                <Route
                  path="announcement/announcement-list"
                  element={<AnnouncementList />}
                />
                <Route path="announcement/add" element={<AnnouncementAdd />} />
                <Route
                  path="announcement/update/:id"
                  element={<AnnouncementAdd />}
                />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("training") ||
            userType() == "super_admin" ? (
              <>
                {" "}
                <Route
                  path="training/training-list"
                  element={<TrainingList />}
                />
                <Route path="training/add" element={<TrainingAdd />} />
                <Route path="training/update/:id" element={<TrainingAdd />} />
                <Route
                  path="training/applicants-details/:id"
                  element={<TrainingApplicants />}
                />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("events") ||
            userType() == "super_admin" ? (
              <>
                {" "}
                <Route path="events/events-list" element={<EventsList />} />
                <Route path="events/add" element={<EventsAdd />} />
                <Route path="events/update/:id" element={<EventsAdd />} />
                <Route
                  path="events/applicants-details/:id"
                  element={<EventsApplicants />}
                />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("benefits") ||
            userType() == "super_admin" ? (
              <>
                {" "}
                <Route
                  path="benefits/benefits-list"
                  element={<BenefitsList />}
                />
                <Route path="benefits/add" element={<BenefitsAdd />} />
                <Route path="benefits/update/:id" element={<BenefitsAdd />} />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("quicklinks") ||
            userType() == "super_admin" ? (
              <>
                <Route
                  path="quick_links/quick_links-list"
                  element={<QuickLinksList />}
                />
                <Route path="quick_links/add" element={<QuickLinksAdd />} />
                <Route
                  path="quick_links/update/:id"
                  element={<QuickLinksAdd />}
                />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("faq") ||
            userType() == "super_admin" ? (
              <>
                <Route path="faq/faq-list" element={<FAQList />} />
                <Route path="faq/add" element={<FAQAdd />} />
                <Route path="faq/update/:id" element={<FAQAdd />} />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("policy") ||
            userType() == "super_admin" ? (
              <>
                {" "}
                <Route path="policy/policy-list" element={<PolicyList />} />
                <Route path="policy/add" element={<PolicyAdd />} />
                <Route path="policy/update/:id" element={<PolicyAdd />} />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("gallery") ||
            userType() == "super_admin" ? (
              <>
                {" "}
                <Route path="gallery/gallery-list" element={<GalleryLists />} />
                <Route path="gallery/add" element={<GalleryAdd />} />
                <Route path="gallery/update/:id" element={<GalleryAdd />} />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("calendar") ||
            userType() == "super_admin" ? (
              <>
                <Route
                  path="calendar/calendar-list"
                  element={<CalenderList />}
                />
                <Route path="calendar/add" element={<CalenderAdd />} />
                <Route path="calendar/update/:id" element={<CalenderAdd />} />
              </>
            ) : (
              <></>
            )}

            {modulePermissionKeys()?.includes("survey") ||
            userType() == "super_admin" ? (
              <>
                <Route path="survey/survey-list" element={<SurveyList />} />
                <Route path="survey/add" element={<SurveyAdd />} />
                <Route path="survey/update/:id" element={<SurveyAdd />} />
                <Route
                  path="survey/view-questions/:id"
                  element={<SurveyViewQuestions />}
                />
                <Route
                  path="survey/answer-details/:surveyId/:questionId"
                  element={<SurveyAnswerDetails />}
                />
                <Route
                  path="survey/view-survey/:id"
                  element={<SurveyView />}
                />
              </>
            ) : (
              <></>
            )}

            {userType() == "super_admin" ? (
              <>
                <Route
                  path="manage-users/user-list"
                  element={<ManageUsers />}
                />
                <Route
                  path="manage-roles/role-list"
                  element={<ManageRoles />}
                />
                <Route path="manage-roles/add" element={<AddRole />} />
                <Route path="manage-roles/update/:id" element={<EditRole />} />
              </>
            ) : (
              <></>
            )}

            {userType() == "super_admin" ? (
              <>
                {" "}
                <Route
                  path="manage-translation/translation-admin"
                  element={<TranslationAdmin />}
                />
                <Route
                  path="manage-translation/translation-web"
                  element={<TranslationWeb />}
                />
              </>
            ) : (
              <></>
            )}

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default RouteHandler;
