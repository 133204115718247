import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import { getSurveyDetail } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  detail_survey_loading,
  survey_details,
} from "../../redux/commonReducer";
import "./survey.scss";
import useTranslate from "../../utils/Hooks/useTranslate";
import TitleValue from "../../commonComponents/TitleValue/TitleValue";
import { Form } from "react-bootstrap";

export default function SurveyView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();
  const lang = localStorage.getItem("lang");

  const loading = useSelector(detail_survey_loading);
  const details = useSelector(survey_details);

  const [questions, setQuestions] = useState([]);

  const typeList = [
    { label: "Radio", value: "radio" },
    { label: "Checkbox", value: "checkbox" },
  ];

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getSurveyDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        title_ar: details?.title_ar,
        publish: details?.publish?.toString(),
      };
      let questionArr = [];
      if (details?.questions?.length) {
        let arr = details?.questions?.map((ele, index) => {
          let obj = {
            // question	: ele?.question,
            // question_ar	: ele?.question_ar,
            ...ele,
            type: typeList?.filter((e) => e.value === ele?.type)?.[0],
            options: ele?.options?.map((e, i) => {
              return {
                options_title: e?.options_title,
                options_title_ar: e?.options_title_ar,
              };
            }),
          };
          return obj;
        });
        questionArr = [...arr];
      }
      console.log(questionArr, "questionArr");
      setQuestions([...questionArr]);
      setInputs({ ...result });
    }
  }, [details]);

  return (
    <div id="survey_addRequest" className="survey-container">
      <section className="mb-4">
        <PageHeader>
          <Title content={"View Survey"} />
          <BreadCrump
            content={[
              {
                name: "View Survey",
              },
              {
                name: "Survey",
                path: "/survey/survey-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={"View Survey"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <div className="d-flex gap-2">
              <div className="col-md-12">
                <TitleValue
                  title="Title"
                  value={lang == "en" ? inputs?.title : inputs?.title_ar}
                />
              </div>
            </div>

            {questions?.map((question, qIndex) => (
              <div key={qIndex} className="card">
                <div className="survey__header">
                  {t("Question")} {qIndex + 1}
                </div>
                <div className="d-flex gap-2">
                  <div className="col-md-8">
                    <TitleValue
                      title="Question"
                      value={
                        lang == "en"
                          ? question?.question
                          : question?.question_ar
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    {" "}
                    <TitleValue
                      title="Comment Required"
                      value={question.is_comment_required ? "Yes" : "No"}
                    />
                  </div>
                </div>
                <p className="titleKey">{`Answer Options (${
                  question?.type?.value == "radio"
                    ? "Single Selection"
                    : "Multiple Selection"
                })`}</p>
                <div className="options-container">
                  {/* {question?.options?.map((option, oIndex) => (
                    <div key={oIndex} className="option-row d-flex gap-2">
                      <div className="col-md-6">
                        <TitleValue
                          title={`${t("Option")} ${oIndex + 1}`}
                          value={
                            lang == "en"
                              ? option.options_title
                              : option.options_title_ar
                          }
                        />
                      </div>
                    </div>
                  ))} */}
                  <div className="d-flex">
                    <div className="survey_options_list">
                      {question?.options?.map((el, i) => {
                        return (
                          <div className="select__box d-flex">
                            {question?.type?.value == "radio" ? (
                              <>
                                <Form.Check
                                  type={"radio"}
                                  id={`default-radio`}
                                  disabled
                                  className="typeBtn"
                                />
                                <Form.Label for={`${i}`}>
                                  {lang === "en"
                                    ? el?.options_title
                                    : el?.options_title_ar}
                                </Form.Label>
                              </>
                            ) : (
                              <>
                                <Form.Check
                                  type={"checkbox"}
                                  id={`default-checkbox`}
                                  disabled
                                />
                                <Form.Label for={`${i}`}>
                                  {lang === "en"
                                    ? el?.options_title
                                    : el?.options_title_ar}
                                </Form.Label>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            ))}
            <div>
              <TitleValue
                title="Publish"
                value={inputs?.publish == 1 ? "Yes" : "No"}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
          </FooterButtons>
        </>
      )}
    </div>
  );
}
