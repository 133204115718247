import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import useTranslate from "../../utils/Hooks/useTranslate";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyDetail } from "../../redux/actionCreator";
import {
  detail_survey_loading,
  survey_details,
} from "../../redux/commonReducer";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { ChartDoughnut, ChartPolar } from "../../components/Charts/Charts";
import CommentView from "../../assets/icons/comment.svg";
import Modal from "../../commonComponents/Modal/Modal";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";
import "./survey.scss";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";

export default function SurveyViewQuestions() {
  const { t } = useTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");

  const details = useSelector(survey_details);
  const loading = useSelector(detail_survey_loading);

  const [questions, setQuestions] = useState([]);
  const [questionTitles, setQuestionTitles] = useState([]);
  const [pollData, setPollData] = useState([]);
  const [comment, setComment] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(getSurveyDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details?.questions) {
      setQuestions(details?.questions);
    }
  }, [details?.questions, lang]);

  useEffect(() => {
    if (details?.questions?.length) {
      let arr = [];
      let temp = [];
      details?.questions?.forEach((item, index) => {
        arr.push({
          title: `Q${index + 1}`,
          key: "q" + index,
        });
        item?.polls?.forEach((ele, ind) => {
          let checkUser = temp?.find((el) => el?._id == ele?.user?._id);
          if (!checkUser) {
            temp.push({
              employee_name: ele?.user?.name,
              department: ele?.user?.department?.[0]?.department_name,
              _id: ele?.user?._id,
              [`q${index}`]: ele?.answer?.length
                ? ele?.answer?.map((e, i) => {
                    return (
                      <p>
                        {lang === "en" ? e?.options_title : e?.options_title_ar}
                        {ele?.comment?.length ? (
                          i == 0 ? (
                            <img
                              src={CommentView}
                              alt="comment"
                              onClick={() => viewCommentHandler(ele?.comment)}
                              className="commentViewStyle"
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </p>
                    );
                  })
                : "--",
            });
          } else {
            let userIndex = temp?.findIndex((e) => e?._id == ele?.user?._id);
            let obj = {
              ...temp[userIndex],
              [`q${index}`]: ele?.answer?.length
                ? ele?.answer?.map((e, i) => {
                    return (
                      <p className="d-flex gap-2">
                        {lang === "en" ? e?.options_title : e?.options_title_ar}
                        {ele?.comment?.length ? (
                          i == 0 ? (
                            <img
                              src={CommentView}
                              alt="comment"
                              onClick={() => viewCommentHandler(ele?.comment)}
                              className="commentViewStyle"
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </p>
                    );
                  })
                : "--",
            };
            temp[userIndex] = obj;
          }
        });
      });
      setQuestionTitles([
        { title: "Employee Name", key: "employee_name" },
        { title: "Department", key: "department" },
        ...arr,
      ]);

      setPollData(temp);
    }
  }, [details, lang]);

  const viewCommentHandler = (comment) => {
    setComment(comment);
    setShow(true);
  };

  const handleClose = () => {
    setComment("");
    setShow(false);
  };

  return (
    <div id="events_listing">
      <section className="mb-4">
        <PageHeader>
          <Title content="View Survey Details" />
          <BreadCrump
            content={[
              {
                name: "View Survey Details",
              },
              {
                name: "Survey",
                path: "/survey/survey-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>

      <section className="mb-4">
        <div id="SearchBox">
          <div className={"inputs mt-4"}>
            {loading ? (
              <></>
            ) : (
              <div>
                <p dir="ltr" className="d-flex">
                  <span>{`${
                    lang == "en" ? details?.title : details?.title_ar
                  }`}</span>
                  <span>{`: ${t("Survey")}`}</span>
                </p>
                <p dir="ltr">
                  {`${details?.answered_users?.length}: ${t(
                    "Answered User Count"
                  )}`}
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={"Survey Chart"}
            toggleButton
            defaultToggle={"hide"}
            top
          >
            <div className="survey_charts survey-container">
              {questions?.map((q, i) => {
                let polls = q.polls
                  .reduce((a, c) => [...a, ...c.answer], [])
                  .reduce(
                    (a, c) => ({ ...a, [c._id]: (a[c._id] || 0) + 1 }),
                    {}
                  );
                let options = q.options.reduce(
                  (a, c) => ({
                    labels: [
                      ...a.labels,
                      lang === "en" ? c.options_title : c.options_title_ar,
                    ],
                    counts: [...a.counts, polls[c._id] || 0],
                  }),
                  { labels: [], counts: [] }
                );
                return q.type === "checkbox" ? (
                  <ChartPolar
                    counts={options.counts}
                    labels={options.labels}
                    title={q[lang === "en" ? "question" : "question_ar"]}
                  />
                ) : (
                  <ChartDoughnut
                    counts={options.counts}
                    labels={options.labels}
                    title={q[lang === "en" ? "question" : "question_ar"]}
                  />
                );
              })}
            </div>
          </TitleBar>

          <TitleBar
            title={"Survey Table"}
            toggleButton
            defaultToggle={"hide"}
            top
          >
            <section className="table">
              <Table
                titles={questionTitles}
                content={pollData}
                className={"css-tbl-esc2"}
                scroll_Max_Rows={10}
              />
              <FooterButtons>
                <Button
                  right
                  className="css-btn-esc3"
                  content="Back"
                  icon={BackIcon}
                  reverse
                  iconWidth="15px"
                  onClick={() => navigate(`/survey/survey-list`)}
                />
              </FooterButtons>
            </section>
          </TitleBar>
        </>
      )}

      <Modal show={show} setShow={() => handleClose()}>
        <ModalContent header={t("View Comment")}>
          <h3
            className={`${
              lang == "en" ? "langENDirectionStyle" : ""
            } right mb-3`}
          >
            {comment}
          </h3>

          <Button
            className="css-btn-esc3"
            onClick={() => handleClose()}
            content={t("Close")}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
