import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../commonComponents/Input/Input";
import { ProgressBar } from "react-bootstrap";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import {
  addGallery,
  getGalleryDetail,
  updateGallery,
  uploadFile,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import SortableList from "../sortFiles/SortableList";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  detail_gallery_loading,
  fileUploadLoader,
  gallery_details,
  update_gallery_loading,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { valueSplitFunction } from "../../utils/helpers";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import useTranslate from "../../utils/Hooks/useTranslate";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";

export default function GalleryAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const loading = useSelector(detail_gallery_loading);
  const details = useSelector(gallery_details);
  const updateLoading = useSelector(update_gallery_loading);
  const uploadLoading = useSelector(fileUploadLoader);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [addAttachmentsLoader, setAddAttachmentsLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [addAttachmentProgress, setAddAttachmentProgress] = useState(0);
  const [trackId, setTrackId] = useState("");

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({ gallery_image: [] });

  useEffect(() => {
    if (id) {
      dispatch(getGalleryDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        title_ar: details?.title_ar,
        description: details?.description,
        description_ar: details?.description_ar,
        publish: details?.publish?.toString(),
      };
      if (details?.gallery_image?.length) {
        let arr = details?.gallery_image?.map((ele, i) => {
          let obj = {
            type: ele?.split(".").pop(),
            url: `${IMAGE_BASE_URL}gallery/${ele}`,
            name: `${ele}`,
            response: `${ele}`,
            id: ele + i + 1,
          };
          return obj;
        });
        result = {
          ...result,
          gallery_image: [...arr],
        };
      }
      if (details?.thumnail?.length) {
        result = {
          ...result,
          thumnail: [
            {
              type: details?.thumnail.split(".").pop(),
              url: `${IMAGE_BASE_URL}gallery/${details?.thumnail}`,
              name: valueSplitFunction(details?.thumnail),
              response: `${details?.thumnail}`,
            },
          ],
        };
      }
      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Gallery") : t("Create Gallery"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        dispatch(
          uploadFile(
            formData,
            (res) => {
              let oldFiles = e?.filter((el) => el?.metaFile == undefined);
              let arr = e
                ?.filter((el) => el?.metaFile != undefined)
                ?.map((ele, i) => {
                  return {
                    ...ele,
                    response: res?.data[i],
                    id: res?.data[i] + i + 1,
                  };
                });
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: [...oldFiles, ...arr],
              }));
              setImageLoader(false);
            },
            (progress) => {
              setAddAttachmentProgress(progress);
            }
          )
        );
      }
    }
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setAddAttachmentsLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        dispatch(
          uploadFile(
            formData,
            (res) => {
              setErrors({
                ...errors,
                [field]: "",
              });
              setInputs((s) => ({
                ...s,
                [field]: [
                  {
                    type: e[0].type,
                    url: e[0].url,
                    name: e[0]?.name,
                    metaFile: e,
                    response: res?.data[0],
                  },
                ],
              }));
              setAddAttachmentsLoader(false);
            },
            (progress) => {
              setAddAttachmentProgress(progress);
            }
          )
        );
      }
    }
  };

  const handleDelete = (file, field) => {
    let temp = [...inputs?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?.id !== file?.id);
      setInputs({
        ...inputs,
        [field]: temp,
      });
    }
  };

  const onDetailsEditorChange = (content, field) => {
    setInputs({
      ...inputs,
      [field]: content,
    });
  };

  const submitHandler = async () => {
    let isValidated = await validate();
    if (isValidated && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("title", inputs?.title ?? "");
    formData.append("title_ar", inputs?.title_ar ?? "");
    formData.append("publish", inputs?.publish ?? "");
    inputs?.gallery_image?.length &&
      inputs?.gallery_image?.forEach((item, i) =>
        formData.append("gallery_image[]", item?.response)
      );
    formData.append("thumnail", inputs?.thumnail?.[0]?.response ?? "");
    formData.append("track_id", trackId);
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updateGallery(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addGallery(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    // navigate(-1);
  };

  return (
    <div id="gallery_addRequest">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit Gallery" : "Add Gallery"} />
          <BreadCrump
            content={[
              {
                name: id ? "Edit Gallery" : "Add Gallery",
              },
              {
                name: "Gallery",
                path: "/gallery/gallery-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={id ? "Edit Gallery" : "Add Gallery"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <Input
              className="css-inp-esc1"
              label="Title in English"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              field={"title"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <Input
              className="css-inp-esc1"
              label="Title in Arabic"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              // validation={{
              //   alphabetsOnly: { lang: "ar", more: '!@#$%^&*()_+-=><.,:;"' },
              // }}
              field={"title_ar"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <div>
              <div className="d-flex gap-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc2  mt-3"
                  label="Upload Thumbnail"
                  buttonLabel="Choose File"
                  accept={["jpg", "jpeg", "png"]}
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      feature_image: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "thumnail"
                    );
                  }}
                  loader={addAttachmentsLoader}
                  error={errors.thumnail}
                  field={"thumnail"}
                  id="thumnail"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.thumnail}
                  required={{ message: t("Please upload thumbnail image") }}
                  inputSocket={formPlug}
                />
              </div>
              <SupportedFormats formats={["jpg", "jpeg", "png"]} maxSize={50} />
            </div>

            <div>
              <div className="d-flex gap-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc2  mt-3"
                  label="Upload Gallery"
                  noLabel
                  buttonLabel="Choose Files"
                  accept={["jpg", "jpeg", "png", "mp4", "mov", "avi"]}
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      gallery_image: "",
                    }));
                    fileSelectedHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "gallery_image"
                    );
                  }}
                  field={"gallery_image"}
                  loader={imageLoader}
                  error={errors.gallery_image}
                  id="gallery_image"
                  maxFileSizeMB={100}
                  multiple={true}
                  files={inputs?.gallery_image}
                  required={{
                    message: t("Please upload galley files"),
                  }}
                  inputSocket={formPlug}
                />
                <ProgressBar progress={addAttachmentProgress} width={350} />
              </div>
              <SupportedFormats
                formats={["jpg", "jpeg", "png", "mp4", "mov", "avi"]}
                maxSize={100}
              />
            </div>
            {inputs?.gallery_image?.length ? (
              <SortableList
                setInputs={setInputs}
                inputs={inputs}
                field="gallery_image"
                handleDelete={handleDelete}
                randomTrackID={trackId ?? ""}
              />
            ) : (
              <></>
            )}
            <div>
              <RadioButtons
                label={"Publish"}
                buttons={[
                  {
                    label: "Yes",
                    value: "1",
                  },
                  {
                    label: "No",
                    value: "0",
                  },
                ]}
                name="publish"
                placeholder={"Select any option"}
                required={{ message: t("Please select publish status") }}
                field={"publish"}
                error={errors.publish}
                value={{
                  publish: inputs?.publish,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Submit"
              onClick={() => submitHandler()}
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Cancel"
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
              onClick={() => onCancelHandler()}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
