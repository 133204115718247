import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../commonComponents/Input/Input";
import { editorConfigAr, editorConfigEn } from "../../utils/editorConfig";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import { addFaq, getFaqDetail, updateFaq } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  detail_faq_loading,
  faq_details,
  fileUploadLoader,
  update_faq_loading,
} from "../../redux/commonReducer";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function FAQAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const loading = useSelector(detail_faq_loading);
  const details = useSelector(faq_details);
  const updateLoading = useSelector(update_faq_loading);
  const uploadLoading = useSelector(fileUploadLoader);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getFaqDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        question: details?.question,
        question_ar: details?.question_ar,
        answer: details?.answer,
        answer_ar: details?.answer_ar,
        publish: details?.publish?.toString(),
      };
      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update FAQ") : t("Create FAQ"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const onDetailsEditorChange = (content, field) => {
    setInputs({
      ...inputs,
      [field]: content,
    });
  };

  const submitHandler = async () => {
    let isValidated = await validate();
    if (isValidated) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("question", inputs?.question ?? "");
    formData.append("question_ar", inputs?.question_ar ?? "");
    formData.append("answer", inputs?.answer ?? "");
    formData.append("answer_ar", inputs?.answer_ar ?? "");
    formData.append("publish", inputs?.publish ?? "");
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updateFaq(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addFaq(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    // navigate(-1);
  };

  return (
    <div id="faq_addRequest">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit FAQ" : "Add FAQ"} />
          <BreadCrump
            content={[
              {
                name: id ? "Edit FAQ" : "Add FAQ",
              },
              {
                name: "FAQ",
                path: "/faq/faq-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={id ? "Edit FAQ" : "Add FAQ"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <Input
              className="css-inp-esc1"
              label="Question in English"
              placeholder="Type here"
              required={{ message: t("Please enter question in english") }}
              field={"question"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <Input
              className="css-inp-esc1"
              label="Question in Arabic"
              placeholder="Type here"
              required={{ message: t("Please enter question in arabic") }}
              field={"question_ar"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <TextEditor
              lang='en'
              config={editorConfigEn}
              label={"Answer in English"}
              className={'editor-ltr'}
              field={"answer"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "answer")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter answer in english") }}
              placeholder="Type Here"
            />
            <TextEditor
              lang='ar'
              config={editorConfigAr}
              label={"Answer in Arabic"}
              style={{ direction: "rtl" }}
              field={"answer_ar"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "answer_ar")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter answer in arabic") }}
              placeholder="Type Here"
            />

            <div>
              <RadioButtons
                label={"Publish"}
                buttons={[
                  {
                    label: "Yes",
                    value: "1",
                  },
                  {
                    label: "No",
                    value: "0",
                  },
                ]}
                name="publish"
                placeholder={"Select any option"}
                required={{ message: t("Please select publish status") }}
                field={"publish"}
                error={errors.publish}
                value={{
                  publish: inputs?.publish,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Submit"
              onClick={() => submitHandler()}
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Cancel"
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
              onClick={() => onCancelHandler()}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
