import React from "react";
import "./DateViewer.scss";
import moment from "moment";

export default function DateViewer({ dateTime, dateOnly, condition, module }) {
  if (!condition && condition !== undefined) return null;

  return (
    <span id="DateViewer">
      {
        <span dir="ltr">
          {dateTime ? (
            <>
              {moment(dateTime).format("DD MMM YYYY")} <br />
              {!dateOnly && " " + moment(dateTime)?.format('h:mm a')}
              {/* {!dateOnly && dateTime && ", " + moment.tz(dateTime, "Asia/Dubai").local().format("hh:mm a")} */}
            </>
          ) : (
            "--"
          )}
        </span>
      }
    </span>
  );
}
