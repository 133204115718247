import { Chart, registerables } from "chart.js";
import { Bar, Doughnut, Pie, PolarArea } from "react-chartjs-2";
import useTranslate from "../../utils/Hooks/useTranslate";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables, ChartDataLabels);
Chart.defaults.datasets.bar.maxBarThickness = 70;

export const ChartDoughnut = ({ counts, labels, keys, onClick, title }) => {
  const { t, lang } = useTranslate();
  return (
    <div id="ChartDoughnut" className="card p-4">
      {title && (
        <p className="title" lang={lang}>
          {t(title)}
        </p>
      )}
      <Doughnut
        options={{
          layout: {
            padding: {
              right: 10,
            },
          },
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
              },
            },
            datalabels: {
              color: "black", // Font color for the labels inside the chart
              font: {
                weight: "bold",
                size: 14, // Adjust font size
              },
              formatter: (value) => (value > 0 ? value : null), // Show the values inside the doughnut segments
            },
          },
          events: ["click"],
          onClick: (a, b, c, d) => {
            onClick && onClick({ incidentType: keys[b[0]?.index] });
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              // label: '# of Votes',
              data: counts,
              backgroundColor: [
                "rgba(136, 8, 8, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                // 'rgba(75, 192, 192, 0.2)',
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(153, 102, 64, 0.2)",
                "rgba(102, 153, 64, 0.2)",
                "rgba(206, 99, 153, 0.2)",
                "rgba(99, 206, 162, 0.2)",
                "rgba(162, 99, 206, 0.2)",
              ],
              borderColor: [
                "rgba(135, 8, 8)",
                "rgba(54, 162, 235)",
                "rgba(255, 206, 86)",
                // 'rgba(75, 192, 192)',
                "rgba(153, 102, 255)",
                "rgba(255, 159, 64)",
                "rgba(153, 102, 64)",
                "rgba(102, 153, 64)",
                "rgba(206, 99, 153)",
                "rgba(99, 206, 162)",
                "rgba(162, 99, 206)",
              ],
              borderWidth: 1,
              hoverOffset: 20,
            },
          ],
        }}
      />
    </div>
  );
};

export const ChartPolar = ({ counts, labels, keys, onClick, title }) => {
  const { t, lang } = useTranslate();
  return (
    <div id="ChartPolar" className="card p-4">
      {title && (
        <p className="title mt-2 p-2" lang={lang}>
          {t(title)}
        </p>
      )}
      <PolarArea
        options={{
          layout: {
            padding: {
              right: 10,
            },
          },
          scales: {
            r: {
              ticks: {
                display: false, // Hides the tick labels (division values)
              },
            }
          },
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
              },
            },
            datalabels: {
              color: "black", // Font color for the labels inside the chart
              font: {
                weight: "bold",
                size: 14, // Adjust font size
              },
              formatter: (value) => (value > 0 ? value : null), // Show the values inside the doughnut segments
            },
          },
          events: ["click"],
          onClick: (a, b, c, d) => {
            onClick && onClick({ incidentType: keys[b[0]?.index] });
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              // label: '# of Votes',
              data: counts,
              backgroundColor: [
                "rgba(136, 8, 8, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                // 'rgba(75, 192, 192, 0.2)',
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                "rgba(153, 102, 64, 0.2)",
                "rgba(102, 153, 64, 0.2)",
                "rgba(206, 99, 153, 0.2)",
                "rgba(99, 206, 162, 0.2)",
                "rgba(162, 99, 206, 0.2)",
              ],
              borderColor: [
                "rgba(135, 8, 8)",
                "rgba(54, 162, 235)",
                "rgba(255, 206, 86)",
                // 'rgba(75, 192, 192)',
                "rgba(153, 102, 255)",
                "rgba(255, 159, 64)",
                "rgba(153, 102, 64)",
                "rgba(102, 153, 64)",
                "rgba(206, 99, 153)",
                "rgba(99, 206, 162)",
                "rgba(162, 99, 206)",
              ],
              borderWidth: 1,
              hoverOffset: 20,
            },
          ],
        }}
      />
    </div>
  );
};

export const ChartBarV = ({ counts, labels, colors, bgColors, title }) => {
  const { t, lang } = useTranslate();
  return (
    <div id="ChartBarV">
      <Bar
        options={{
          indexAxis: "x",
          elements: {
            bar: {
              borderWidth: 2,
            },
          },
          responsive: true,
          plugins: {
            legend: {
              position: "top",
              display: false,
            },
            title: {
              display: false,
              text: "Chart.js Horizontal Bar Chart",
            },
          },
        }}
        data={{
          labels: labels?.map((l) => t(l)),
          datasets: [
            {
              label: "",
              data: counts,
              // backgroundColor: [
              //     'rgba(49, 175, 222, .2)',
              //     'rgba(255, 187, 0, .2)',
              //     'rgba(217, 82, 82, .2)',
              //     'rgba(39, 168, 113, .2)',
              //     'rgba(47, 32, 155, .2)',
              //     'rgba(12, 103, 9, .2)',
              //     'rgba(49, 175, 222, .2)',
              //     'rgba(222, 112, 18, .2)',
              //     'rgba(109, 153, 255, .2)',
              // ],
              backgroundColor: colors,
              borderColor: bgColors,
              // borderColor: [
              //     '#31AFDE',
              //     '#FFBB00',
              //     '#D95252',
              //     '#27A871',
              //     '#2F209B',
              //     '#0C6709',
              //     '#31AFDE',
              //     '#DE7012',
              //     '#6D99FF',
              // ],
              borderRadius: 5,
              borderSkipped: false,
            },
            // {
            //     label: 'Dataset 2',
            //     data: labels.map(() => Math.random() * 100),
            //     borderColor: 'rgb(53, 162, 235)',
            //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
            // },
          ],
        }}
      />
      {title && (
        <p className="title" lang={lang}>
          {t(title)}
        </p>
      )}
    </div>
  );
};
