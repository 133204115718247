import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../commonComponents/Input/Input";
import { editorConfigAr, editorConfigEn } from "../../utils/editorConfig";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import {
  addPolicy,
  getDepartments,
  getPolicyDetail,
  updatePolicy,
  uploadFile,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  department_List,
  detail_policy_loading,
  fileUploadLoader,
  policy_details,
  update_policy_loading,
} from "../../redux/commonReducer";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { valueSplitFunction } from "../../utils/helpers";
import TextEditor from "../../commonComponents/TextEditor/TextEditor";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import Select from "../../commonComponents/Select/Select";
import useTranslate from "../../utils/Hooks/useTranslate";
import SupportedFormats from "../../commonComponents/SupportedFormats/SupportedFormats";

export default function PolicyAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const lang = localStorage.getItem("lang");
  const { t } = useTranslate();

  const loading = useSelector(detail_policy_loading);
  const details = useSelector(policy_details);
  const updateLoading = useSelector(update_policy_loading);
  const uploadLoading = useSelector(fileUploadLoader);
  const departmentList = useSelector(department_List);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [pdfLoader, setPdfLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getPolicyDetail(id));
    }
  }, [id]);

  useEffect(() => {
    dispatch(getDepartments());
  }, []);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        title_ar: details?.title_ar,
        description: details?.description,
        description_ar: details?.description_ar,
        department: details?.department,
        publish: details?.publish?.toString(),
      };
      if (details?.pdf?.length) {
        result = {
          ...result,
          pdf: [
            {
              type: details?.pdf.split(".").pop(),
              url: `${IMAGE_BASE_URL}policy/${details?.pdf}`,
              name: valueSplitFunction(details?.pdf),
              response: `${details?.pdf}`,
            },
          ],
        };
      }
      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Policy") : t("Create Policy"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const singleFileUploadHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErrors({
        ...errors,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErrors({
        ...errors,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setPdfLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        formData.append("file", e[0]?.metaFile);
        dispatch(
          uploadFile(formData, (res) => {
            setErrors({
              ...errors,
              [field]: "",
            });
            setInputs((s) => ({
              ...s,
              [field]: [
                {
                  type: e[0].type,
                  url: e[0].url,
                  name: e[0]?.name,
                  metaFile: e,
                  response: res?.data[0],
                },
              ],
            }));
            setPdfLoader(false);
          })
        );
      }
    }
  };

  const onDetailsEditorChange = (content, field) => {
    setInputs({
      ...inputs,
      [field]: content,
    });
  };

  const submitHandler = async () => {
    let isValidated = await validate();
    if (isValidated && !invalidUpload) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("title", inputs?.title ?? "");
    formData.append("title_ar", inputs?.title_ar ?? "");
    formData.append("description", inputs?.description ?? "");
    formData.append("description_ar", inputs?.description_ar ?? "");
    formData.append("department", inputs?.department?._id ?? "");
    formData.append("publish", inputs?.publish ?? "");
    formData.append("pdf", inputs?.pdf?.[0]?.response ?? "");
    formData.append("track_id", trackId);
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updatePolicy(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addPolicy(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    // navigate(-1);
  };

  return (
    <div id="policy_addRequest">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit Policy" : "Add Policy"} />
          <BreadCrump
            content={[
              {
                name: id ? "Edit Policy" : "Add Policy",
              },
              {
                name: "Policy",
                path: "/policy/policy-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <TitleBar
            title={id ? "Edit Policy" : "Add Policy"}
            toggleButton
            defaultToggle={"show"}
            className={"css-tbr-esc1"}
          >
            <Select
              className="css-slc-esc1"
              placeholder={"Select Department"}
              title={"Select Department"}
              options={departmentList}
              value={inputs?.department}
              labelSetter={(e) =>
                lang === "en" ? e.department_name : e.department_name_ar
              }
              valueSetter={(e) => e._id}
              inputSocket={formPlug}
              onChange={inputHandler}
              error={errors.department}
              field={"department"}
              required={{ message: t("Please select department") }}
            />
            <Input
              className="css-inp-esc1"
              label="Title in English"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              field={"title"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <Input
              className="css-inp-esc1"
              label="Title in Arabic"
              placeholder="Type here"
              required={{ message: t("Please enter title in english") }}
              // validation={{
              //   alphabetsOnly: { lang: "ar", more: '!@#$%^&*()_+-=><.,:;"' },
              // }}
              field={"title_ar"}
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
            />
            <TextEditor
              lang='en'
              config={editorConfigEn}
              label={"Description in English"}
              className={"editor-ltr"}
              field={"description"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "description")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter description in english") }}
              placeholder="Type Here"
            />
            <TextEditor
              lang='ar'
              config={editorConfigAr}
              label={"Description in Arabic"}
              style={{ direction: "rtl" }}
              field={"description_ar"}
              onBlur={(newContent) =>
                onDetailsEditorChange(newContent, "description_ar")
              }
              value={inputs}
              error={errors}
              inputSocket={formPlug}
              onChange={inputHandler}
              required={{ message: t("Please enter description in arabic") }}
              placeholder="Type Here"
            />
            <div>
              <div className="d-flex gap-3">
                <FileUpload
                  style={{
                    marginRight: 5,
                    marginTop: 15,
                  }}
                  className="css-atc-esc2  mt-3"
                  label="Upload Pdf"
                  buttonLabel="Choose File"
                  accept={["pdf", "PDF"]}
                  onChange={(e, isInvalid, message, isClosing) => {
                    setErrors((s) => ({
                      ...s,
                      pdf: "",
                    }));
                    singleFileUploadHandler(
                      e,
                      isInvalid,
                      message,
                      isClosing,
                      "pdf"
                    );
                  }}
                  loader={pdfLoader}
                  error={errors.pdf}
                  field={"pdf"}
                  id="pdf"
                  maxFileSizeMB={50}
                  multiple={false}
                  showPreview
                  files={inputs?.pdf}
                  inputSocket={formPlug}
                />
              </div>
              <SupportedFormats formats={["pdf", "PDF"]} maxSize={50} />
            </div>
            <div>
              <RadioButtons
                label={"Publish"}
                buttons={[
                  {
                    label: "Yes",
                    value: "1",
                  },
                  {
                    label: "No",
                    value: "0",
                  },
                ]}
                name="publish"
                placeholder={"Select any option"}
                required={{ message: t("Please select publish status") }}
                field={"publish"}
                error={errors.publish}
                value={{
                  publish: inputs?.publish,
                }}
                onChange={(value, key) => inputHandler(value, key)}
                inputSocket={formPlug}
              />
            </div>
          </TitleBar>

          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Submit"
              onClick={() => submitHandler()}
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
            />
            <Button
              left
              className="css-btn-esc3"
              content="Cancel"
              loader={updateLoading || uploadLoading}
              disabled={updateLoading || uploadLoading}
              onClick={() => onCancelHandler()}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
