import { useDispatch, useSelector } from "react-redux";
import { dashboard_data, dashboard_loading } from "../../redux/commonReducer";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { dashboardCounts } from "../../redux/actionCreator";
import React, { useEffect, useState } from "react";
import Breadcrump from "../../commonComponents/Breadcrump/Breadcrump";
import Title from "../../commonComponents/Title/Title";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import useTranslate from "../../utils/Hooks/useTranslate";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const loader = useSelector(dashboard_loading);
  const dashboardData = useSelector(dashboard_data);

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(dashboardCounts());
  }, []);

  // useEffect(() => {
  //   if (dashboardData) {
  //     setData([
  //       { label: "Total News", value: dashboardData?.news_count },
  //       {
  //         label: "Total Announcements",
  //         value: dashboardData?.announcement_count,
  //       },
  //       { label: "Total Benefits", value: dashboardData?.benefits_count },
  //       { label: "Total Calendar", value: dashboardData?.calender_count },
  //       { label: "Total Events", value: dashboardData?.events_count },
  //       { label: "Total FAQ", value: dashboardData?.faq_count },
  //       { label: "Total Galley", value: dashboardData?.gallery_count },
  //       { label: "Total Policy", value: dashboardData?.policy_count },
  //       { label: "Quick Links", value: dashboardData?.quicklinks_count },
  //       { label: "Total Survey", value: dashboardData?.survey_count },
  //       { label: "Total Training", value: dashboardData?.training_count },
  //     ]);
  //   }
  // }, [dashboardData]);
  return (
    console.log(data),
    (
      <div>
        {loader ? (
          <LoadingSpinner height={"300px"} />
        ) : (
          <div className="dashboard__lists">
            <PageHeader>
              <Title content={"Dashboard"} />
              <Breadcrump
                content={[
                  {
                    name: "Dashboard",
                  },
                  {
                    name: "Home",
                    path: "/",
                  },
                ]}
              />
            </PageHeader>
            <div className="row">
              {dashboardData?.map((ele, i) => {
                return (
                  <div className="col-md-3" key={i}>
                    <div className="dashboard__card">
                      <h4>{t(ele?.label)}</h4>
                      <h2>{ele?.value}</h2>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    )
  );
}
