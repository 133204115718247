import React, { useEffect, useState } from "react";
import useFilter from "../../utils/Hooks/useFilter";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Table from "../../commonComponents/Table/Table";
import useTranslate from "../../utils/Hooks/useTranslate";
import GenericSearchIcon from "../../assets/icons/Search1.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  exportSurveyAnsweredQuestionAndUsers,
  getSurveyDetail,
  surveyAnsweredUsers,
} from "../../redux/actionCreator";
import {
  detail_survey_loading,
  survey_answer_details,
  survey_answer_pages,
  survey_details,
} from "../../redux/commonReducer";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import UploadIcon from "../../assets/icons/upload_export.svg";
import CommentView from "../../assets/icons/comment.svg";
import Modal from "../../commonComponents/Modal/Modal";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";

export default function SurveyAnswerDetails() {
  const { t } = useTranslate();
  const { surveyId, questionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = localStorage.getItem("lang");

  const details = useSelector(survey_answer_details);
  const loading = useSelector(detail_survey_loading);
  const pageCount = useSelector(survey_answer_pages);
  const surveyDetails = useSelector(survey_details);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
    date_from: "",
    date_to: "",
    keyword: "",
  };

  const [data, setData] = useState([]);
  const [questionTitles, setQuestionTitles] = useState([]);
  const [pollData, setPollData] = useState([]);
  const [comment, setComment] = useState("");
  const [show, setShow] = useState(false);

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    if (surveyId) {
      dispatch(getSurveyDetail(surveyId));
    }
  }, [surveyId]);

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  useEffect(() => {
    if (surveyDetails?.questions?.length) {
      let arr = [];
      let temp = [];
      surveyDetails?.questions?.forEach((item, index) => {
        arr.push({
          title: `Q${index + 1}`,
          key: "q" + index,
        });
        item?.polls?.forEach((ele, ind) => {
          let checkUser = temp?.find((el) => el?._id == ele?.user?._id);
          if (!checkUser) {
            temp.push({
              employee_name: ele?.user?.name,
              _id: ele?.user?._id,
              [`q${index}`]: ele?.answer?.length
                ? ele?.answer?.map((e, i) => {
                    return (
                      <p>
                        {lang === "en" ? e?.options_title : e?.options_title_ar}
                        {ele?.comment?.length ? (
                          i == 0 ? (
                            <img
                              src={CommentView}
                              alt="comment"
                              onClick={() => viewCommentHandler(ele?.comment)}
                              className="commentViewStyle"
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </p>
                    );
                  })
                : "--",
            });
          } else {
            let userIndex = temp?.findIndex((e) => e?._id == ele?.user?._id);
            let obj = {
              ...temp[userIndex],
              [`q${index}`]: ele?.answer?.length
                ? ele?.answer?.map((e, i) => {
                    return (
                      <p className="d-flex gap-2">
                        {lang === "en" ? e?.options_title : e?.options_title_ar}
                        {ele?.comment?.length ? (
                          i == 0 ? (
                            <img
                              src={CommentView}
                              alt="comment"
                              onClick={() => viewCommentHandler(ele?.comment)}
                              className="commentViewStyle"
                            />
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                      </p>
                    );
                  })
                : "--",
            };
            temp[userIndex] = obj;
          }
        });
      });
      setQuestionTitles([
        { title: "Employee Name", key: "employee_name" },
        ...arr,
      ]);

      setPollData(temp);
    }
  }, [surveyDetails, lang]);

  const viewCommentHandler = (comment) => {
    setComment(comment);
    setShow(true);
  };

  useEffect(() => {
    setData([]);
    if (details?.answered_users) {
      details?.answered_users?.forEach((item, i) => {
        setData((s) => [
          ...s,
          {
            employee_id: item?.user?.[0]?.employee_id,
            employee_name: item?.user?.[0]?.name,
            department: item?.user?.[0]?.department?.[0]?.department_name ?? (
              <span className="mr-5">--</span>
            ),
            section: item?.user?.[0]?.section[0]?.section_name ?? (
              <span className="mr-5">--</span>
            ),
            designation: item?.user?.[0].designation?.[0]?.designation_name || (
              <span className="mr-5">--</span>
            ),
            answer: item?.answer?.length
              ? item?.answer?.map((ele) => {
                  return (
                    <p>
                      {lang === "en"
                        ? ele?.options_title
                        : ele?.options_title_ar}
                    </p>
                  );
                })
              : "",
            comment: item?.comment ?? "--",
          },
        ]);
      });
    }
  }, [details?.answered_users, lang]);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("id", surveyId);
    formData.append("question_id", questionId);
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    dispatch(surveyAnsweredUsers(formData));
  };

  const handleClose = () => {
    setComment("");
    setShow(false);
  };

  return (
    <div id="survey_listing">
      <section className="mb-4">
        <PageHeader>
          <Title content="View Survey Answer Details" />
          <BreadCrump
            content={[
              {
                name: "View Survey Answer Details",
              },
              {
                name: "View Survey Details",
                path: `/survey/view-questions/${surveyId}`,
              },
              {
                name: "Survey",
                path: "/survey/survey-list",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <section className="table">
          <Table
            titles={questionTitles}
            content={pollData}
            className={"css-tbl-esc2"}
          />
          <FooterButtons>
            <Button
              right
              className="css-btn-esc3"
              content="Back"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(`/survey/view-questions/${surveyId}`)}
            />
          </FooterButtons>
        </section>
      )}

      <Modal show={show} setShow={() => handleClose()}>
        <ModalContent header={t("View Comment")}>
          <h3
            className={`${
              lang == "en" ? "langENDirectionStyle" : ""
            } right mb-3`}
          >
            {comment}
          </h3>

          <Button
            className="css-btn-esc3"
            onClick={() => handleClose()}
            content={t("Close")}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
