import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import Breadcrump from "../../commonComponents/Breadcrump/Breadcrump";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import GenericSearchIcon from "../../assets/icons/Search1.svg";
import AddRequesticon from "../../assets/icons/Add Request.svg";
import Button from "../../commonComponents/Button/Button";
import Modal from "../../commonComponents/Modal/Modal";
import ModalContent from "../../commonComponents/ModalContent/ModalContent";
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../commonComponents/Actions/Actions";
import EditIcon from "../../assets/icons/Edit.svg";
import DeleteIcon from "../../assets/icons/delete.svg";
import { useNavigate } from "react-router-dom";
import {
  translation_data_list,
  translation_data_page_status,
  translation_data_pages,
  translation_loader,
  update_translation_loading,
} from "../../redux/commonReducer";
import {
  deleteText,
  errorToast,
  getTexts,
  insertText,
  successToast,
  updateText,
} from "../../redux/actionCreator";
import useTranslate from "../../utils/Hooks/useTranslate";
import useFilter from "../../utils/Hooks/useFilter";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";

export default function TranslationAdmin() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();

  const loader = useSelector(translation_loader);
  const allData = useSelector(translation_data_list);
  const pageCount = useSelector(translation_data_pages);
  const pageStatus = useSelector(translation_data_page_status);
  const updateLoading = useSelector(update_translation_loading);

  const [edit, setEdit] = useState(false);
  const [addEditModal, setAddEditModal] = useState(false);
  const [addData, setAddData] = useState({
    title: "",
    english_text: "",
    arabic_text: "",
  });
  const [err, setErr] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [data, setData] = useState([]);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
  };

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
  });

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  useEffect(() => {
    setData([]);
    if (allData) {
      allData?.forEach((item, index) => {
        setData((s) => [
          ...s,
          {
            sl_no: index + 1,
            title: item?.title,
            english_text: item?.english_text,
            arabic_text: item?.arabic_text,
            action: (
              <Actions
                buttons={{
                  edit: EditIcon,
                  // delete: DeleteIcon,
                }}
                visibility={{
                  // delete: !item?.is_default,
                  edit: !item?.is_default,
                }}
                onEdit={() => editHandler(item)}
                onDelete={() => showDeletePopup(item?._id)}
              />
            ),
          },
        ]);
      });
    }
  }, [allData]);

  const editHandler = (ele) => {
    setAddData({
      id: ele?._id,
      title: ele?.title,
      english_text: ele?.english_text,
      arabic_text: ele?.arabic_text,
    });
    setEdit(true);
    setAddEditModal(true);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }
    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const getSearchDataHandler = (filter) => {
    let rawData = {
      page: filter?.page || 0,
      perPage: filter?.perPage || 10,
      keyword: filter?.keyword?.trim() || "",
    };
    dispatch(getTexts(rawData));
  };

  const addHandler = () => {
    setEdit(false);
    setAddEditModal(true);
  };

  const onAddChangeHandler = (key, value) => {
    setAddData({
      ...addData,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let obj = {};
    if (addData?.title?.trim()?.length == 0) {
      obj = {
        ...obj,
        title: "Please enter title",
      };
    }
    if (addData?.english_text?.trim()?.length == 0) {
      obj = {
        ...obj,
        english_text: "Please enter text in english",
      };
    }
    if (addData?.arabic_text?.trim()?.length == 0) {
      obj = {
        ...obj,
        arabic_text: "Please enter text in arabic",
      };
    }
    if (Object.values(obj).length != 0) {
      setErr(obj);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onAddHandler = () => {
    if (validate()) {
      let value = {
        title: addData?.title?.trim(),
        english_text: addData?.english_text?.trim(),
        arabic_text: addData?.arabic_text?.trim(),
      };
      console.log(value);
      if (edit) {
        value = {
          ...value,
          id: addData?.id,
        };
        dispatch(
          updateText(value, (res) => {
            if (!res.status) {
              setErr((s) => ({ ...s, arabic_text: res.message }));
            } else {
              successToast(res.message);
              handleCloseAddModal();
              setFilterData(initialFilter);
              getSearchDataHandler(initialFilter);
            }
          })
        );
      } else {
        dispatch(
          insertText(value, (res) => {
            console.log(res, "res");
            if (!res.status) {
              setErr((s) => ({ ...s, arabic_text: res.message }));
            } else {
              successToast(res.message);
              handleCloseAddModal();
              setFilterData(initialFilter);
              getSearchDataHandler(initialFilter);
            }
          })
        );
      }
    }
  };

  const handleCloseAddModal = () => {
    setAddData({
      title: "",
      english_text: "",
      arabic_text: "",
      id: "",
    });
    setErr({});
    setEdit(false);
    setAddEditModal(false);
  };

  function CancelHandler() {
    setAddData({
      title: "",
      english_text: "",
      arabic_text: "",
      id: "",
    });
    setErr({});
    setEdit(false);
    setAddEditModal(false);
  }

  const deleteHandler = () => {
    const value = {
      id: deleteID,
    };
    dispatch(
      deleteText(value, (res) => {
        if (!res.status) {
          errorToast(res.message);
        } else {
          successToast(res.message);
          setShowDeleteModal(false);
          if (allData?.length == 1 && pageCount > 1 && filterData?.page != 0) {
            setFilterData({
              ...filterData,
              page: filterData?.page - 1,
            });
            let rawData = {
              page: filterData?.page - 1 || 0,
              perPage: filterData?.perPage || 10,
              keyword: filterData?.keyword?.trim() || "",
            };
            dispatch(getTexts(rawData));
          } else {
            getSearchDataHandler(filterData);
          }
        }
      })
    );
  };

  const clearSearch = () => {
    setFilterData(initialFilter);
    getSearchDataHandler(initialFilter);
  };

  return (
    <div id="MD_outputFiles">
      <section className="mb-4">
        <PageHeader>
          <Title content={"Admin Translation"} />
          <Breadcrump
            content={[
              {
                name: "Admin Translation",
                path: "",
              },
              {
                name: "Home",
                path: "/",
              },
            ]}
          />

          {/* <Button
            content={"Add Translation"}
            className="css-btn-esc1"
            icon={AddRequesticon}
            reverse
            onClick={addHandler}
          /> */}
        </PageHeader>
      </section>
      <section className="d-flex justify-content-between mb-4">
        <EntriesCounter
          value={filterData.perPage}
          onChange={(e) => {
            filterHandler("entries", e);
          }}
        />
        <Input
          value={filterData.keyword}
          type="text"
          className="css-inp-esc2 search"
          icon={GenericSearchIcon}
          placeholder={t("Generic Search")}
          reverse
          width="300px"
          field="keyword"
          showCloseBtn
          onSubmit={(value) => {
            filterHandler("keyword", value);
          }}
          onIconClick={(value) => {
            filterHandler("keyword", value);
          }}
          onChange={(value) => {
            if (!value) {
              clearSearch();
            }
          }}
        />
      </section>
      {loader ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <section className="table">
          <Table
            className={"css-tbl-esc2"}
            titles={[
              { title: "Sl No", key: "sl_no" },
              {
                title: "Title",
                key: "title",
              },
              {
                title: "Text in English",
                key: "english_text",
              },
              {
                title: "Text in Arabic",
                key: "arabic_text",
              },
              { title: "Action", key: "action" },
            ]}
            content={data}
            onSort={(e) => filterHandler("sort", e)}
            page={filterData.page}
            pagesCount={pageCount}
            pageStatus={pageStatus}
            onPageChange={(e) => {
              filterHandler("page", e.selected);
            }}
            sortValue={{
              key: filterData.sortColumn,
              value: filterData.sortValue,
            }}
          />
        </section>
      )}

      <Modal show={addEditModal} setShow={() => handleCloseAddModal()}>
        <ModalContent header={`${edit ? "Edit" : "Add"} Translation`}>
          <Input
            type="text"
            className="css-inp-esc1"
            placeholder={t("Type here")}
            label={t("Title")}
            error={err.title}
            value={addData?.title}
            field={"title"}
            onChange={(value, key) => onAddChangeHandler(key, value)}
            required
            inputSocket={() => {}}
            disabled={edit}
          />
          <Input
            type="text"
            className="css-inp-esc1"
            placeholder={t("Type here")}
            label={t("Text in English")}
            error={err.english_text}
            value={addData?.english_text}
            field={"english_text"}
            onChange={(value, key) => onAddChangeHandler(key, value)}
            required
            inputSocket={() => {}}
          />
          <Input
            type="text"
            className="css-inp-esc1"
            placeholder={t("Type here")}
            label={t("Text in Arabic")}
            error={err.arabic_text}
            value={addData?.arabic_text}
            field={"arabic_text"}
            onChange={(value, key) => onAddChangeHandler(key, value)}
            required
            inputSocket={() => {}}
          />
          <Button
            className="css-btn-esc3"
            content={"Submit"}
            onClick={onAddHandler}
            disabled={updateLoading}
            loader={updateLoading}
          />
          <Button
            className="css-btn-esc3"
            content={"Cancel"}
            onClick={CancelHandler}
            disabled={updateLoading}
            loader={updateLoading}
          />
        </ModalContent>
      </Modal>

      <Modal show={showDeleteModal} setShow={() => setShowDeleteModal()}>
        <ModalContent header={t("Delete Translation")}>
          <h3
            className={`${
              lang == "en" ? "langENDirectionStyle" : ""
            } right mb-3`}
          >
            {t("Are you sure to") + " " + t("delete") + " " + t("?")}
          </h3>
          <Button
            className="css-btn-esc3"
            onClick={deleteHandler}
            content={t("Yes")}
            disabled={updateLoading}
            loader={updateLoading}
          />
          <Button
            className="css-btn-esc3"
            onClick={() => setShowDeleteModal(false)}
            content={t("No")}
            disabled={updateLoading}
            loader={updateLoading}
          />
        </ModalContent>
      </Modal>
    </div>
  );
}
