import React, { useEffect, useState } from "react";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import Breadcrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import {
  addPermissions,
  getDepartments,
  getModules,
  getSection,
  getUsersWithNoPermissions,
  getUsersWithoutPermissions,
} from "../../redux/actionCreator";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTranslate from "../../utils/Hooks/useTranslate";
import {
  department_List,
  modules,
  section_details,
  update_permission_loading,
  updateRedux,
  wo_permission_full_user_list,
  wo_permission_user_list,
  wo_permission_user_page_status,
  wo_permission_user_pages,
} from "../../redux/commonReducer";
import Table from "../../commonComponents/Table/Table";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import BackIcon from "../../assets/icons/Back.svg";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Form } from "react-bootstrap";
import "./role.scss";
import SearchBox from "../../commonComponents/SearchBox/SearchBox";
import Checkbox from "../../commonComponents/Checkbox/Checkbox";

export default function AddRole() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { id } = useParams();
  const lang = localStorage.getItem("lang");

  const departmentList = useSelector(department_List);
  const sectionList = useSelector(section_details);
  const user_list = useSelector(wo_permission_full_user_list);
  const moduleList = useSelector(modules);
  const updateLoading = useSelector(update_permission_loading);
  const finalSearchData = useSelector(wo_permission_user_list);
  const finalSearchPageCount = useSelector(wo_permission_user_pages);
  const finalSearchPageStatus = useSelector(wo_permission_user_page_status);

  const [err, setErr] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [module, setModule] = useState([]);

  const initialFilter = {
    page: 0,
    perPage: 10,
    department: "",
    section: "",
    user: "",
  };

  const [filterData, setFilterData] = useState(initialFilter);
  const [showAddSection, setShowAddSection] = useState(false);

  useEffect(() => {
    dispatch(getModules());
    dispatch(getDepartments());
    dispatch(updateRedux({ key: "section_details", value: [] }));
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getUsersWithoutPermissions(formData));
  }, []);

  const onCancel = () => {
    setFilterData(initialFilter);
    setSelectedUsers([]);
    setModule([]);
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getUsersWithoutPermissions(formData));
    setShowAddSection(false);
    setErr({});
  };

  const validate = () => {
    let temp = {};
    if (module?.length == 0) {
      temp = { ...temp, module: t("Please Select Permission") };
    }
    if (selectedUsers?.length == 0) {
      temp = { ...temp, user: t("Please Select Users") };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let data = {
        modules: module ?? [],
        users: selectedUsers.map((item) => item?._id),
      };
      console.log(data);
      dispatch(
        addPermissions(data, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Permission") : t("Add Permission"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 3000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 3000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };

  function searchBoxChangeHandler(value, key, setValues, values) {
    if (key === "department") {
      setValues((s) => ({
        ...s,
        department: value,
        user: "",
        section: "",
      }));
      dispatch(updateRedux({ key: "section_details", value: [] }));
      value._id && dispatch(getSection(value._id));
      let formData = new FormData();
      formData.append("department", value?._id || "");
      formData.append("section", "");
      dispatch(getUsersWithoutPermissions(formData));
    }
    if (key === "section") {
      setValues((s) => ({ ...s, section: value, searchUser: "" }));
      let formData = new FormData();
      formData.append(
        "department",
        values.department ? values?.department?._id : ""
      );
      formData.append("section", value?._id);
      dispatch(getUsersWithoutPermissions(formData));
    }
    if (key === "user") {
      setValues((s) => ({ ...s, user: value }));
    }
  }

  function filterHandler(key, value) {
    console.log(key, value);
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }
    if (!filter) return;
    setFilterData(filter);
    if (key == "clearSearch") {
      setShowAddSection(false);
      dispatch(updateRedux({ key: "section_details", value: [] }));
      let formData = new FormData();
      formData.append("department", "");
      formData.append("section", "");
      dispatch(getUsersWithoutPermissions(formData));
      setSelectedUsers([]);
    } else {
      addFilterHandler(filter, () => {
        setShowAddSection(true);
      });
    }
  }

  const addFilterHandler = (filter, callback) => {
    let formData = new FormData();
    formData.append(
      "department",
      filter?.department ? filter?.department?._id : ""
    );
    formData.append("section", filter?.section ? filter?.section?._id : "");
    formData.append("user", filter?.user ? filter?.user?._id : "");
    formData.append("page", filter?.page);
    formData.append("perPage", filter?.perPage);
    dispatch(
      getUsersWithNoPermissions(formData, () => {
        if (callback) {
          callback();
        }
      })
    );
  };

  const clearEmployeeList = () => {
    let formData = new FormData();
    formData.append("department", "");
    formData.append("section", "");
    dispatch(getUsersWithoutPermissions(formData));
  };

  const handleCheckEmployee = (employee, checked) => {
    if (checked) {
      setSelectedUsers((prev) => [...prev, employee]);
      setErr({
        ...err,
        user: "",
      });
    } else {
      setSelectedUsers(selectedUsers.filter((item) => item !== employee));
    }
  };

  return (
    <div id="meet_addRequest" className="">
      <section className="mb-4">
        <PageHeader>
          <Title content={id ? "Edit Permission" : "Add Permission"} />
          <Breadcrump
            content={[
              { name: id ? "Edit Permission" : "Add Permission" },
              {
                name: "Permission",
                path: "",
              },

              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {false ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <section>
            <TitleBar
              title="Add User Permission"
              toggleButton
              defaultToggle={"show"}
              className={"css-tbr-esc1"}
            >
              <section className="mt-4">
                <SearchBox
                  className="css-shb-esc2 select__employee"
                  elements={[
                    {
                      label: "Department",
                      key: "department",
                      type: "select",
                      className: "css-slc-esc1 mb-0",
                      placeholder: "Select",
                      width: "250px",
                    },
                    {
                      label: "Section",
                      key: "section",
                      type: "select",
                      className: "css-slc-esc1 mb-0",
                      placeholder: "Select",
                      width: "250px",
                    },
                    {
                      label: "User",
                      key: "user",
                      type: "select",
                      className: "css-slc-esc1 mb-0",
                      placeholder: "Select",
                      width: "250px",
                    },
                  ]}
                  options={{
                    department: {
                      options: departmentList,
                      labelSetter: (o) =>
                        lang === "en"
                          ? o?.department_name
                          : o?.department_name_ar,
                      valueSetter: (o) => o._id,
                      value: filterData.department,
                    },
                    section: {
                      options: sectionList,
                      labelSetter: (o) =>
                        lang === "en" ? o?.section_name : o?.section_name_ar,
                      valueSetter: (o) => o._id,
                      value: filterData.section,
                    },
                    user: {
                      options: user_list,
                      labelSetter: (o) => o.name,
                      valueSetter: (o) => o._id,
                      value: filterData.user,
                    },
                  }}
                  onChange={searchBoxChangeHandler}
                  onSearch={(values) => {
                    filterHandler("searchBox", values);
                  }}
                  onClear={() => {
                    filterHandler("clearSearch");
                    clearEmployeeList();
                  }}
                  dependency={[filterData]}
                />
              </section>
              <section className="mt-4">
                {showAddSection && (
                  <Table
                    className="css-tbl-esc3"
                    titles={[
                      { title: "Employee No", key: "employee_no" },
                      { title: "Employee Name", key: "employee_name" },
                      { title: "Department", key: "department" },
                      { title: "Section", key: "section" },
                      { title: "Designation", key: "designation" },
                      { title: "Action", key: "action" },
                    ]}
                    content={finalSearchData?.map((ele, i) => {
                      return {
                        employee_no: ele?.employee_id,
                        employee_name: ele?.name,
                        section: ele?.section?.length
                          ? lang === "en"
                            ? ele?.section[0]?.section_name
                            : ele?.section[0]?.section_name_ar
                          : "-",
                        department:
                          lang === "en"
                            ? ele.department.department_name ?? "-"
                            : ele.department.department_name_ar ?? "-",
                        designation: ele?.designation?.designation_name ?? "-",
                        action: (
                          <Checkbox
                            className={"mt-2"}
                            label=""
                            size={20}
                            checked={selectedUsers?.some(
                              (item) => item._id === ele._id
                            )}
                            onChange={(value) =>
                              handleCheckEmployee(ele, value)
                            }
                          />
                        ),
                      };
                    })}
                    page={filterData?.page}
                    pagesCount={finalSearchPageCount}
                    pageStatus={finalSearchPageStatus}
                    onPageChange={(e) => {
                      filterHandler("page", e.selected);
                    }}
                  />
                )}
              </section>
              <small id="error" className="error">
                {err?.user}
              </small>
              <div className="row">
                <TitleBar title="Select User Permission" />
                <div className="module__list">
                  {moduleList?.map((ele, i) => {
                    return (
                      <div className="select__box">
                        <Form.Check
                          type="checkbox"
                          id={`${ele?._id}`}
                          checked={module.includes(ele._id) ? true : false}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setModule([...module, ele._id]);
                              setErr({ ...err, module: "" });
                            } else {
                              setModule(
                                module.filter((item) => item != ele._id)
                              );
                            }
                          }}
                        />
                        <Form.Label for={`${i}`}>{ele.module_name}</Form.Label>
                      </div>
                    );
                  })}
                </div>
                <small id="error" className="error">
                  {err?.module}
                </small>
              </div>
            </TitleBar>
          </section>

          <FooterButtons>
            <Button
              right
              content="Back"
              className="css-btn-esc3"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              content="Submit"
              className="css-btn-esc3"
              onClick={() => onSubmitHandler()}
              disabled={updateLoading}
              loader={updateLoading}
            />
            <Button
              left
              content="Cancel"
              className="css-btn-esc3"
              onClick={() => onCancel()}
              disabled={updateLoading}
              loader={updateLoading}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
