import React, { useEffect, useState } from "react";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import Breadcrump from "../../commonComponents/Breadcrump/Breadcrump";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import {
  getModules,
  getPermissionDetails,
  updatePermissions,
} from "../../redux/actionCreator";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTranslate from "../../utils/Hooks/useTranslate";
import {
  detail_permission_loading,
  modules,
  permission_details,
  update_permission_loading,
} from "../../redux/commonReducer";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import BackIcon from "../../assets/icons/Back.svg";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Form } from "react-bootstrap";
import "./role.scss";
import PersonalInformation from "../general/PersonalInformation/PersonalInformation";

export default function EditRole() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { id } = useParams();
  const lang = localStorage.getItem("lang");

  const moduleList = useSelector(modules);
  const updateLoading = useSelector(update_permission_loading);
  const loading = useSelector(detail_permission_loading);
  const details = useSelector(permission_details);

  const [err, setErr] = useState({});
  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [module, setModule] = useState([]);

  useEffect(() => {
    dispatch(getModules());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getPermissionDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (details?.permissions) {
      setModule(details?.permissions ?? []);
    }
  }, [details]);

  const onCancel = () => {
    setModule(details?.permissions ?? []);
    setErr({})
  };

  const validate = () => {
    let temp = {};
    if (module?.length == 0) {
      temp = { ...temp, module: t("Please Select Permissions") };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let data = {
        modules: module ?? [],
        user: details?._id,
      };
      console.log(data);
      dispatch(
        updatePermissions(data, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Permission") : t("Add Permission"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 4000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 4000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 4000);
    }
  };

  return (
    <div id="meet_addRequest" className="">
      <section className="mb-4">
        <PageHeader>
          <Title content={"Edit Permission"} />
          <Breadcrump
            content={[
              { name: "Edit Permission" },
              {
                name: "Permission",
                path: "",
              },

              {
                name: "Home",
                path: "/",
              },
            ]}
          />
        </PageHeader>
      </section>
      {loading ? (
        <LoadingSpinner height={"200px"} />
      ) : (
        <>
          <section>
            <TitleBar
              title="Personal Information"
              toggleButton
              defaultToggle={"show"}
            >
              <PersonalInformation user={details} />
            </TitleBar>
            <TitleBar
              title="Update Permission"
              toggleButton
              defaultToggle={"show"}
              className={"css-tbr-esc1"}
            >
              <div className="row">
                <div className="module__list">
                  {moduleList?.map((ele, i) => {
                    return (
                      <div className="select__box d-flex">
                        <Form.Check
                          type="checkbox"
                          id={`${ele?._id}`}
                          checked={module.includes(ele._id) ? true : false}
                          onClick={(e) => {
                            if (e.target.checked) {
                              setModule([...module, ele._id]);
                              setErr({ ...err, module: "" });
                            } else {
                              setModule(
                                module.filter((item) => item != ele._id)
                              );
                            }
                          }}
                        />
                        <Form.Label for={`${i}`}>{ele.module_name}</Form.Label>
                      </div>
                    );
                  })}
                </div>
                <p>
                  {t(
                    "If you would to delete all permissions, kindly delete the user from the listing page"
                  )}
                </p>
                <small id="error" className="error">
                  {err?.module}
                </small>
              </div>
            </TitleBar>
          </section>
          <FooterButtons>
            <Button
              right
              content="Back"
              className="css-btn-esc3"
              icon={BackIcon}
              reverse
              iconWidth="15px"
              onClick={() => navigate(-1)}
            />
            <Button
              left
              content="Update"
              className="css-btn-esc3"
              onClick={() => onSubmitHandler()}
              disabled={updateLoading}
              loader={updateLoading}
            />
            <Button
              left
              content="Cancel"
              className="css-btn-esc3"
              onClick={() => onCancel()}
              disabled={updateLoading}
              loader={updateLoading}
            />
          </FooterButtons>
        </>
      )}
      <StatusModal
        show={showModal}
        setShow={setShowModal}
        header={successMessage?.title}
        isError={successMessage?.isError}
      >
        {successMessage?.message}
      </StatusModal>
    </div>
  );
}
