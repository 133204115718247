import React, { useEffect, useState } from "react";
import PageHeader from "../../commonComponents/PageHeader/PageHeader";
import Title from "../../commonComponents/Title/Title";
import BreadCrump from "../../commonComponents/Breadcrump/Breadcrump";
import useForm from "../../utils/Hooks/useForm";
import Button from "../../commonComponents/Button/Button";
import FooterButtons from "../../commonComponents/FooterButtons/FooterButtons";
import BackIcon from "../../assets/icons/Back.svg";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import Input from "../../commonComponents/Input/Input";
import TitleBar from "../../commonComponents/TitleBar/TitleBar";
import {
  addSurvey,
  getSurveyDetail,
  updateSurvey,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import RadioButtons from "../../commonComponents/RadioButtons/RadioButtons";
import {
  detail_survey_loading,
  fileUploadLoader,
  survey_details,
  update_survey_loading,
} from "../../redux/commonReducer";
import StatusModal from "../../commonComponents/StatusModal/StatusModal";
import Select from "../../commonComponents/Select/Select";
import "./survey.scss";
import DeleteIcon from "../../assets/icons/delete.svg";
import CloseIcon from "../../assets/icons/close.svg";
import Checkbox from "../../commonComponents/Checkbox/Checkbox";
import useTranslate from "../../utils/Hooks/useTranslate";
import { Accordion } from "react-bootstrap";
import TextArea from "../../commonComponents/TextArea/TextArea";

export default function SurveyAdd() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslate();

  const loading = useSelector(detail_survey_loading);
  const details = useSelector(survey_details);
  const updateLoading = useSelector(update_survey_loading);
  const uploadLoading = useSelector(fileUploadLoader);

  const [successMessage, setSuccessMessage] = useState({
    message: "",
    title: "",
    isError: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [questions, setQuestions] = useState([
    {
      question: "",
      question_ar: "",
      questionType: "radio",
      is_comment_required: false,
      options: [
        { options_title: "", options_title_ar: "" },
        { options_title: "", options_title_ar: "" },
      ],
    },
  ]);
  const [err, setErr] = useState({});

  const typeList = [
    { label: "Radio", value: "radio" },
    { label: "Checkbox", value: "checkbox" },
  ];

  const {
    errors,
    inputHandler,
    inputs,
    formPlug,
    validate,
    setErrors,
    setInputs,
    clearInputs,
  } = useForm({});

  useEffect(() => {
    if (id) {
      dispatch(getSurveyDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        title_ar: details?.title_ar,
        publish: details?.publish?.toString(),
      };
      let questionArr = [];
      if (details?.questions?.length) {
        let arr = details?.questions?.map((ele, index) => {
          let obj = {
            // question	: ele?.question,
            // question_ar	: ele?.question_ar,
            ...ele,
            type: typeList?.filter((e) => e.value === ele?.type)?.[0],
            options: ele?.options?.map((e, i) => {
              return {
                options_title: e?.options_title,
                options_title_ar: e?.options_title_ar,
              };
            }),
          };
          return obj;
        });
        questionArr = [...arr];
      }
      console.log(questionArr, "questionArr");
      setQuestions([...questionArr]);
      setInputs({ ...result });
    }
  }, [details]);

  const successActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: id ? t("Update Survey") : t("Create Survey"),
      message: res.message,
    });
    setShowModal(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const errActionAfterApiResponse = (res) => {
    setSuccessMessage({
      title: t("Alert"),
      message: res.message,
      isError: true,
    });
    setShowModal(true);
    if (res?.data?.redirect) {
      setTimeout(() => {
        setShowModal(false);
        navigate(-1);
      }, 1000);
    } else {
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
  };

  const onCancelHandler = () => {
    setErrors({});
    clearInputs();
    setQuestions([]);
    setErr({});
    // navigate(-1);
  };

  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        question: "",
        question_ar: "",
        questionType: "radio",
        is_comment_required: false,
        options: [{ options_title: "", options_title_ar: "" },{ options_title: "", options_title_ar: "" }],
      },
    ]);
    setErr({
      ...err,
      questions: "",
    });
  };

  const handleQuestionChange = (index, value, field) => {
    const newQuestions = [...questions];
    newQuestions[index][field] = value;
    setQuestions(newQuestions);
    setErr({
      ...err,
      [`${field}_${index}`]: "",
    });
  };

  const handleOptionChange = (qIndex, oIndex, value, field) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options[oIndex][field] = value;
    setQuestions(newQuestions);
    setErr({
      ...err,
      [`${field}_${qIndex}_${oIndex}`]: "",
    });
  };

  const addOption = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].options.push({
      options_title: "",
      options_title_ar: "",
    });
    setQuestions(newQuestions);
  };

  const removeOption = (qIndex, oIndex) => {
    const newQuestions = [...questions];
    newQuestions[qIndex].options.splice(oIndex, 1);
    setQuestions(newQuestions);
  };

  const removeQuestion = (index) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const validateFields = () => {
    const newErrors = {};
    if (questions?.length == 0) {
      newErrors[`questions`] = t("Please add minimum one question");
    }
    questions.forEach((question, qIndex) => {
      if (!question.question) {
        newErrors[`question_${qIndex}`] = t("Question in english is required");
      }
      if (!question.question_ar) {
        newErrors[`question_ar_${qIndex}`] = t(
          "Question in arabic is required"
        );
      }
      if (!question.type) {
        newErrors[`type_${qIndex}`] = t("Type is required");
      }
      question.options.forEach((option, oIndex) => {
        if (!option.options_title) {
          newErrors[`options_title_${qIndex}_${oIndex}`] = t(
            "Option in english is required"
          );
        }
        if (!option.options_title_ar) {
          newErrors[`options_title_ar_${qIndex}_${oIndex}`] = t(
            "Option in arabic is required"
          );
        }
      });
    });

    setErr(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitHandler = async () => {
    let isValidated = await validate({
      required: { accept: ["title", "title_ar", "publish"] },
    });

    let isValidated2 = await validateFields();
    console.log(isValidated2, isValidated, errors);
    if (isValidated && isValidated2) {
      finalSubmitHandler();
    }
  };

  const finalSubmitHandler = () => {
    let formData = new FormData();
    formData.append("title", inputs?.title ?? "");
    formData.append("title_ar", inputs?.title_ar ?? "");
    formData.append("description", inputs?.description ?? "");
    formData.append("description_ar", inputs?.description_ar ?? "");
    formData.append("publish", inputs?.publish ?? "");
    questions?.forEach((item, index) => {
      formData.append(`questions[${index}][question]`, item?.question);
      formData.append(`questions[${index}][question_ar]`, item?.question_ar);
      formData.append(
        `questions[${index}][is_multiple_choice]`,
        item?.type?.value == "checkbox" ? true : false
      );
      formData.append(`questions[${index}][type]`, item?.type?.value);
      formData.append(
        `questions[${index}][is_comment_required]`,
        item?.is_comment_required
      );
      item?.options?.forEach((el, i) => {
        formData.append(
          `questions[${index}][options][${i}][options_title]`,
          el?.options_title
        );
        formData.append(
          `questions[${index}][options][${i}][options_title_ar]`,
          el?.options_title_ar
        );
      });
    });
    console.log(formData, "form");
    if (id) {
      formData.append("id", id);
      dispatch(
        updateSurvey(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    } else {
      dispatch(
        addSurvey(formData, (res) => {
          if (res.status) {
            successActionAfterApiResponse(res);
          } else {
            errActionAfterApiResponse(res);
          }
        })
      );
    }
  };

  return (
    console.log(questions),
    (
      <div id="survey_addRequest" className="survey-container">
        <section className="mb-4">
          <PageHeader>
            <Title content={id ? "Edit Survey" : "Add Survey"} />
            <BreadCrump
              content={[
                {
                  name: id ? "Edit Survey" : "Add Survey",
                },
                {
                  name: "Survey",
                  path: "/survey/survey-list",
                },
                {
                  name: "Home",
                  path: "/",
                },
              ]}
            />
          </PageHeader>
        </section>
        {loading ? (
          <LoadingSpinner height={"200px"} />
        ) : (
          <>
            <TitleBar
              title={id ? "Edit Survey" : "Add Survey"}
              toggleButton
              defaultToggle={"show"}
              className={"css-tbr-esc1"}
            >
              <div className="titles">
                <TextArea
                  className="css-txt-esc1 editor-ltr"
                  label="Title in English"
                  placeholder="Type here"
                  required={{ message: "Please enter title in english" }}
                  field={"title"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
                <TextArea
                  className="css-txt-esc1"
                  label="Title in Arabic"
                  placeholder="Type here"
                  required={{ message: "Please enter title in arabic" }}
                  // validation={{
                  //   alphabetsOnly: {
                  //     lang: "ar",
                  //     more: '!@#$%^&*()_+-=><.,:;"',
                  //   },
                  // }}
                  field={"title_ar"}
                  value={inputs}
                  error={errors}
                  inputSocket={formPlug}
                  onChange={inputHandler}
                />
              </div>

              {questions?.map((question, qIndex) => (
                <div key={qIndex} className="card">
                  {questions?.length !== 1 ? (
                    <div
                      className="question-delete-icon"
                      onClick={() => removeQuestion(qIndex)}
                    >
                      <img src={DeleteIcon} alt="" />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="questions">
                    <TextArea
                      className="css-txt-esc1"
                      label="Question in English"
                      placeholder="Type here"
                      required
                      field={"question"}
                      value={question.question}
                      error={err[`question_${qIndex}`]}
                      inputSocket={() => {}}
                      onChange={(value, key) =>
                        handleQuestionChange(qIndex, value, "question")
                      }
                    />
                    <TextArea
                      className="css-txt-esc1 "
                      label="Question in Arabic"
                      placeholder="Type here"
                      required
                      field={"question_ar"}
                      value={question.question_ar}
                      error={err[`question_ar_${qIndex}`]}
                      inputSocket={() => {}}
                      onChange={(value, key) =>
                        handleQuestionChange(qIndex, value, "question_ar")
                      }
                    />
                  </div>
                  <Select
                    className="css-slc-esc1 select-type mt-4"
                    placeholder={"Select Type"}
                    title={"Select type"}
                    options={typeList}
                    value={question.type}
                    labelSetter={(e) => e.label}
                    valueSetter={(e) => e.value}
                    inputSocket={() => {}}
                    onChange={(value, key) =>
                      handleQuestionChange(qIndex, value, "type")
                    }
                    error={err[`type_${qIndex}`]}
                    field={"type"}
                    required
                  />

                  <div className="options-container">
                    {question?.options?.map((option, oIndex) => (
                      <div key={oIndex} className="option-row d-flex gap-2">
                        <Input
                          className="css-inp-esc1"
                          label={`${t("Option")} ${oIndex + 1} ${t("in English")}`}
                          placeholder="Type here"
                          required
                          field={"options_title"}
                          value={option.options_title}
                          inputSocket={() => {}}
                          onChange={(value, key) =>
                            handleOptionChange(
                              qIndex,
                              oIndex,
                              value,
                              "options_title"
                            )
                          }
                          error={err[`options_title_${qIndex}_${oIndex}`]}
                        />
                        <Input
                          className="css-inp-esc1"
                          label={`${t("Option")} ${oIndex + 1} ${t("in Arabic")}`}
                          placeholder="Type here"
                          required
                          field={"options_title_ar"}
                          value={option.options_title_ar}
                          inputSocket={() => {}}
                          onChange={(value, key) =>
                            handleOptionChange(
                              qIndex,
                              oIndex,
                              value,
                              "options_title_ar"
                            )
                          }
                          error={err[`options_title_ar_${qIndex}_${oIndex}`]}
                        />
                        {oIndex == 0 || oIndex == 1 ? (
                          <></>
                        ) : (
                          <div>
                            <img
                              className="option-delete-icon"
                              src={CloseIcon}
                              alt=""
                              onClick={() => removeOption(qIndex, oIndex)}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                    <Button
                      className="css-btn-esc3 add-option-button mt-2"
                      content="Add Option"
                      iconWidth="15px"
                      onClick={() => addOption(qIndex)}
                    />
                  </div>
                  <hr />
                  <Checkbox
                    label="Comment Required"
                    className="css-tgl-esc1 comment-require"
                    inputSocket={() => {}}
                    field={"is_comment_required"}
                    toggleSwitch={{ true: "Yes", false: "No" }}
                    checked={question.is_comment_required}
                    onChange={(value, key) => {
                      handleQuestionChange(
                        qIndex,
                        value,
                        "is_comment_required"
                      );
                    }}
                  />
                  {/* <Button
                    className="css-btn-esc3 mt-2 remove-question-button"
                    content="Remove Question"
                    iconWidth="15px"
                    onClick={() => removeQuestion(qIndex)}
                  /> */}
                </div>
              ))}

              <Button
                className="css-btn-esc3 add-question-button"
                content="Add Question"
                iconWidth="15px"
                onClick={addQuestion}
              />
              <small id="error" className="error">
                {err?.questions}
              </small>
              <div>
                <RadioButtons
                  label={"Publish"}
                  buttons={[
                    {
                      label: "Yes",
                      value: "1",
                    },
                    {
                      label: "No",
                      value: "0",
                    },
                  ]}
                  name="publish"
                  placeholder={"Select any option"}
                  required={{ message: t("Please select publish status") }}
                  field={"publish"}
                  error={errors.publish}
                  value={{
                    publish: inputs?.publish,
                  }}
                  onChange={(value, key) => inputHandler(value, key)}
                  inputSocket={formPlug}
                />
              </div>
            </TitleBar>

            <FooterButtons>
              <Button
                right
                className="css-btn-esc3"
                content="Back"
                icon={BackIcon}
                reverse
                iconWidth="15px"
                onClick={() => navigate(-1)}
              />
              <Button
                left
                className="css-btn-esc3"
                content="Submit"
                onClick={() => submitHandler()}
                loader={updateLoading || uploadLoading}
                disabled={updateLoading || uploadLoading}
              />
              <Button
                left
                className="css-btn-esc3"
                content="Cancel"
                loader={updateLoading || uploadLoading}
                disabled={updateLoading || uploadLoading}
                onClick={() => onCancelHandler()}
              />
            </FooterButtons>
          </>
        )}
        <StatusModal
          show={showModal}
          setShow={setShowModal}
          header={successMessage?.title}
          isError={successMessage?.isError}
        >
          {successMessage?.message}
        </StatusModal>
      </div>
    )
  );
}
